import React from "react";
import { Link } from "gatsby";
import loadable from "@loadable/component";

const Parser = loadable(() => import("../parser.js"));
const Image = loadable(() => import("./image.js"));

import "../../styles/blocks/navgrid.scss";

const NavGrid = (props) => {
  let className = props.attributes.className;
  if (props.attributes.align && props.attributes.align.length) {
    className += " align"+props.attributes.align;
  }
  const images = props.innerBlocks.map(e => {
    const title = props.navGridTitleForButton
      ? (e.attributes.title || "Découvrir")
      : "En savoir plus";
    const TileContent = () => {
      return <>
        {
          props.navGridAsBackgroundImages
            ? (<></>)
            : (<Image size="medium" attributes={ e.attributes } />)
        }
        {
          props.navGridTitleForButton || !e.attributes.title
            ? (<></>)
            : (<h3>{ e.attributes.title }</h3>)
        }
        { (e.attributes.caption && e.attributes.caption.length)
          ? (<p>{ <Parser content={ e.attributes.caption } /> }</p>)
          : (<></>)
        }
        { (e.attributes.href && e.attributes.href.length)
          ? (<div className="wp-block-button is-style-button-primary">
            <span
              className={"wp-block-button__link "+e.attributes.linkClass}
            >
              { title }
            </span>
          </div>)
          : (<></>)
        }
      </>;
    };
    return (
      <div
        id={ e.anchor }
        className={ "tile" + ((e.attributes.className) ? " "+e.attributes.className: "")}
        key={e.attributes.id}
        style={{ 
          backgroundImage: props.navGridAsBackgroundImages
            ? "url("+e.url+")"
            : undefined, 
          ...(props.style || {})
        }}>
        {
          e.attributes.href && e.attributes.href.length
            ? <Link
              target={e.attributes.linkTarget}
              href={e.attributes.href}
              rel={e.attributes.rel}
              title={(e.attributes.title && e.attributes.title.length) ? (title + " : " + e.attributes.title) : title }
            >
              <TileContent />
            </Link>
            : <TileContent />
        }
      </div>);
  });
  return (<nav id={ props.anchor } className={ className }>
    { images }
  </nav>);
};

export default NavGrid;
